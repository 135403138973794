import Dexie from "dexie";
import { DBStores } from "./DBStores";

class database {
    db = new Dexie("osnDB");

    constructor() {
        this.open();
    }

    async open() {
        if(this.db.isOpen())
            return; 

        await this.db.version(1).stores({
            [DBStores.ChatRoomMessages]: 'chatRoomId',
            [DBStores.ChatRoom]: 'id',
            [DBStores.HelpRequest]: 'id, helpStatus'
        });

        await this.db.open().catch (function (err) {
            console.error('Failed to open db: ' + (err.stack || err));
        })
    }

    delete() {
        this.db.delete();
    }

    mapToClass(tableName, constructor) {
        //TODO: for some reason, this doesn't work :/
        this.db.table(tableName).mapToClass(constructor);
    }

    insertOrReplace(tableName, obj) {
        return this.db.table(tableName).put(obj);
    }

    update(tableName, key, changes) {
        return this.db.table(tableName).update(key, changes);
    }

    getAllData(tableName) {
        return new Promise((resolve, reject) => {
            this.db.on('ready', () => {
                this.db.table(tableName).toArray()
                    .then(result => {
                        resolve(result);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        });
    }
 
    getData(tableName, ids) {
        return new Promise((resolve, reject) => {
            if (ids === null || ids === undefined) {
                this.db.table(tableName).toArray()
                    .then(result => {
                        resolve(result);
                    })
                    .catch(error => {
                        reject(error);
                    });
            } else if (Array.isArray(ids)) {
                if (ids.length === 0) {
                    this.db.table(tableName).toArray()
                        .then(result => {
                            resolve(result);
                        })
                        .catch(error => {
                            reject(error);
                        });
                } else {
                    this.db.table(tableName).bulkGet(ids)
                        .then(result => {
                            resolve(result);
                        })
                        .catch(error => {
                            reject(error);
                        });
                }
            } else {
                this.db.table(tableName).get(ids)
                    .then(result => {
                        resolve(result);
                    })
                    .catch(error => {
                        reject(error);
                    });
            }
        });
    }
}

export const osnDB = new database();