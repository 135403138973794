<template>
    <div class="toast-container position-fixed top-0 start-50 translate-middle-x p-3 " id="toastPlacement">
        <div :id="'liveToast'+i" class="toast align-items-center show" :class="this.toastTypeColour(t.type)" v-for="(t, i) in toasts" :key="i" role="alert" 
            data-bs-autohide="true" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
<!--                 <div class="row mt-2">
                        <div class="col-1"></div>
                        <div class="col-9">
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped w-100" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div class="col-2 text-end">
                            <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" v-on:click="this.disposeToast(i)"></button>
                        </div>
                </div> -->
                <div class="toast-body">
                    {{t.message}}
                </div>
                <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" v-on:click="this.disposeToast(i)"></button>
            </div>
        </div>
    </div>
</template>

<script>
import {Toast, ToastType} from '../models/Toast.js';

export default {
    name:"toast",
    props: {
        message: {}, //old style, will be removed
        toast: Toast
    },
    computed: {
        ToastType() {
            return ToastType;
        }
    },
    data: function() {
        return {
            toasts : [],
            toastCount: 0,
        }
    },
    watch: {
        'message': function() 
        {
            if(this.message != '' || this.message != null || this.message != undefined)
            {
                //TODO: Move to a Toast model
                let temp = {
                    id: ++this.toastCount,
                    message:this.message.message,
                    timeoutId: setTimeout( ()=> { 
                        this.disposeToastById(temp.id);
                    }, 3000),
                    timePassed: 1,
                    intervalId: setInterval(() => {
                            if(temp.timePassed == 5) 
                            {
                                clearInterval(temp.intervalId);
                                return;
                            }

                            //console.log(temp.timePassed);
                            ++temp.timePassed; 
                        }, 1000)
                };

                this.toasts.push(temp);
            }
        },
        toast: function(newValue) 
        {
            if(newValue != undefined && newValue != null) 
            {
                //TODO: Move to a Toast model
                let temp = {
                    id: ++this.toastCount,
                    message:newValue.message,
                    type: newValue.type,
                    timeoutId: setTimeout( ()=> { 
                        this.disposeToastById(temp.id);
                    }, newValue.duration),
                    timePassed: 1,
                    intervalId: setInterval(() => {
                            if(temp.timePassed == 5) 
                            {
                                clearInterval(temp.intervalId);
                                return;
                            }

                            //console.log(temp.timePassed);
                            ++temp.timePassed; 
                        }, 1000)
                };

                this.toasts.push(temp);
            }
        }
    },
    methods: {
        toastTypeColour(toastType) {
            if(toastType === ToastType.ALERT)
                return "bg-danger text-white";
            else if(toastType === ToastType.INFO)
                return "bg-info text-white";
            else
                return '';
        },
        disposeToast: function(index) {
            this.toasts.splice(index, 1);
        },
        disposeToastById: function(id)
        {
            for (var i = 0; i < this.toasts.length; i++)
            {
                if(this.toasts[i].id != id)
                    continue;

                this.disposeToast(i);
            }
        }
    }
}
</script>