import {LocalStorageKeys, EndPoints} from '../constants/consts.js';
import logger from '../utilities/logger.js';
const signalr = require('@microsoft/signalr');

class ChatHub {
    #connection = new signalr.HubConnectionBuilder()
                    .withUrl(EndPoints.Host+"chatHub",  { accessTokenFactory: () => localStorage.getItem(LocalStorageKeys.BearerToken) })
                    .configureLogging(signalr.LogLevel.Debug)
                    .withAutomaticReconnect({
                        nextRetryDelayInMilliseconds: () => {
                            // wait between 0 and 10 seconds before the next reconnect attempt.
                            return Math.random() * 10000;
                        /* if (retryContext.elapsedMilliseconds < 60000) {
                            // If we've been reconnecting for less than 60 seconds so far,
                            // wait between 0 and 10 seconds before the next reconnect attempt.
                            return Math.random() * 10000;
                        } else {
                            // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                            return null;
                        } */
                    }})
                    .build();

    #keepAliveTimer;

    constructor() {
        this.#connection.onreconnecting(this.onreconnecting.bind(this));
        this.#connection.onreconnected(this.onreconnected.bind(this));
        this.#connection.onclose(this.onclose.bind(this));
    }

    get state() {
        return this.#connection.state;
    }

    onConnectionStateChanged = null;

    onreconnecting() {
        logger.logDev("Reconnecting...");
        if(this.onConnectionStateChanged)
            this.onConnectionStateChanged(this.state);
    }
    onreconnected(connectionId) {
        logger.logDev("Reconnected with connection ID: " + connectionId);
        if(this.onConnectionStateChanged)
            this.onConnectionStateChanged(this.state);
    }
    onclose(error) {
        if(error)
            console.error("Connection closed with error: " + error);
        else
            console.log("Connection closed.");
    }

    start() {
        this.#connection.start( () => {
            // Set up a keep-alive timer
            const keepAliveInterval = 30000; // 30 seconds (adjust as needed)
            this.#keepAliveTimer = setInterval(function () {
                this.#connection.invoke("KeepAlive"); // You need to define a "KeepAlive" method on the server
            }, keepAliveInterval);
        });
    }

    stop() {
        this.#connection.stop(function () {
            clearInterval(this.#keepAliveTimer);
        });
    }

    async sendMessage(chatRoomId, message) {
        let val = await this.#connection.invoke("sendMessage", chatRoomId, message)
        return val;
    }

    receiveMessage(callBack) {
        this.#connection.on("ReceiveMessage", callBack);
    }

    receiveOwnMessage(callBack) {
        this.#connection.on("ReceiveOwnMessage", callBack);
    }

    joinChatRoom(callBack) {
        this.#connection.on("JoinChatRoom", callBack);
    }
}

export const chatHub = new ChatHub();
