import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import jquery from 'jquery'
import PrimeVue from 'primevue/config';

let app = createApp(App);

//app.config.globalProperties.chatHub = chatHub;

app.use(PrimeVue)
   .use(router)
   .use(jquery)
   .mount('#app')
