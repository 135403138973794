export class User {
    id=0
    firstName=''
    lastName=''
    email=''
    maxResponseDistance=0
    isUserActive=false
    isEmailVerified=false
    locations=[]
    userSatCom={}
    locale='en-CA'
}