import { BaseStore } from "@/stores/BaseStore";
import { HelpRequest } from "@/models/HelpRequest";
import { DBStores } from "./DBStores";

export class HelpRequestStore extends BaseStore {

    constructor() {
        super(DBStores.HelpRequest);
        super.mapToClass(HelpRequest);
    }

    insertOrReplaceHelpRequest(helpRequest)  {
        return super.insertOrReplace(helpRequest);
    }

    getData(id) {
        return super.getData(id);
    }

    getAllData() {
        return super.getAllData();
    }
}