export default class blogs {
    //TODO: move this to utilities??
    //TODO: Read blog posts from an api or something
    blogPosts = {};

    constructor() {
        try
        {
            this.blogPosts = require('./blogPosts.json');
        }
        catch{
            this.blogPosts = {}
        }
    }

    getPosts() {
        return this.blogPosts;
    }
}