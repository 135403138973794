import { ChatRoomMessagesStore } from "./ChatRoomMessagesStore";
import { ChatRoomStore } from "./ChatRoomStore";
import { HelpRequestStore } from "./HelpRequestStore";
import { DBStores } from "./DBStores";

class DBStoreFactory {
    #stores = {};

    constructor() {
        this.refreshStores();
    }

    refreshStores() {
        this.#stores[DBStores.ChatRoom] = new ChatRoomStore();
        this.#stores[DBStores.ChatRoomMessages] = new ChatRoomMessagesStore();
        this.#stores[DBStores.HelpRequest] = new HelpRequestStore();
    }

    getStore(storeName) {
        return this.#stores[storeName]; 
    }
}

export const dbStoreFactory = new DBStoreFactory();