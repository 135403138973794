import {BaseStore} from "@/stores/BaseStore";
import { DBStores } from "./DBStores";

export class ChatRoomMessagesStore extends BaseStore {

    constructor() {
        super(DBStores.ChatRoomMessages);
    }

    insertOrAppendChatRoomMessages(chatRoomId, messages) {
        let lastMessageId = messages?.at(-1)?.id ?? "-1"; //right now this is just for debugging purposes

        return this.getMessages(chatRoomId).then( chats => {
           if(chats === undefined) {
               return super.insertOrReplace({chatRoomId: chatRoomId, lastMessageId: lastMessageId, messages: messages});
           }

            console.log("insertChatRoomMessages:chatsFound:")
            chats.messages = chats.messages.concat(messages);
            return super.insertOrReplace(chats);
        });
    }

    insertOrReplaceChatRoomMessages(chatRoomId, messages) {
        let lastMessageId = messages?.at(-1)?.id ?? "-1"; //right now this is just for debugging purposes

        return this.getMessages(chatRoomId).then( chats => {
           if(chats === undefined) {
               return super.insertOrReplace({chatRoomId: chatRoomId, lastMessageId: lastMessageId, messages: messages});
           }

            chats.messages = messages;
            return super.insertOrReplace(chats);
        });
    }

    getMessages(chatRoomId) {
        return super.getData(chatRoomId);
    }
}