//TODO: Make Location > User Location w/ Id, and TripLocation > Location
export class Location {
    country = ''
    region = ''
    city = ''
    geoLocation = new GeoLocation()
}

export class UserLocation extends Location {
    id = ''
    isActive = false
    description = ''

    init(location) {
        this.country = location.country;
        this.region = location.region;
        this.city = location.city;
        this.geoLocation.latitude = location.geoLocation.latitude;
        this.geoLocation.longitude = location.geoLocation.longitude;
        this.description = [this.city, this.region, this.country].join(', ');
        return this;
    }
}

export class GeoLocation {
    latitude = 0.0
    longitude = 0.0

    init(lat, lng) { //overridden constructor
        this.latitude = lat;
        this.longitude = lng;
        return this;
    }
}

export class TripLocation {
    country = ''
    region = ''
    city = ''
    geoLocation = new GeoLocation()
    description = ''
}