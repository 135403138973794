import {LocalStorageKeys, EndPoints} from '../constants/consts.js';
import { osnDB } from '../stores/db.js';

//If AccessToken is expired; tries to fetch with a new refresh token
//on failure return to Login
//otherwise go with the fetch
//if fetch comes back with 401 - return to login
export default async function(endPoint, method, body)
{
    endPoint = endPoint.replaceAll(' ','-');
    if(isAccessTokenExpired())
    {
        if(! await refreshToken())
        {
            console.log("refresh token failed. Going to login.");
            logout();
        }
    }

    if(method == "GET")
    {
        return fetchGet(endPoint);
    }

    if(method == "FILE")
    {
        return fetchFile(endPoint, body);
    }

    return fetch(endPoint, {
        method: method,
        body: body,
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": "Bearer " + localStorage.getItem(LocalStorageKeys.BearerToken)
        }
    }).then(response => {
        if(response.status == "401")
        {
            logout();
        }

        return response;
    });
}

function isAccessTokenExpired()
{
    let expiry = new Date(localStorage.getItem(LocalStorageKeys.BearerTokenExpiryUtc));
    let local = new Date();

    if(expiry == "Invalid Date")
        return true;

    return local >= expiry;
}

function fetchGet(endPoint)
{
    return fetch(endPoint, {
        method: "GET",
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": "Bearer " + localStorage.getItem(LocalStorageKeys.BearerToken)
        }
    }).then(response => {
        if(response.status == "401")
        {
            console.log("osn fetch get response 401")
            logout();
        }

        return response;
    });
}

function fetchFile(endPoint, file)
{
    return fetch(endPoint, {
        method: "POST",
        body: file,
        headers: {
            "Authorization": "Bearer " + localStorage.getItem(LocalStorageKeys.BearerToken)
        }
    }).then(response => {
        if(response.status == "401")
        {
            logout();
        }

        return response;
    });
}

export async function refreshToken() {
    let isSuccess = false;
    await fetch(EndPoints.Authentication+'refresh', {
        method: "POST",
        body: '"'+localStorage.getItem(LocalStorageKeys.RefreshToken)+'"',
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            }
        })
        .then(response => { 
            if(response.ok){
                return response.json()
            }
            
            return false;
        })
        .then(response => {
                if(response !== false)
                {
                    console.log('writing new Tokens : ' + response)
                    localStorage.setItem(LocalStorageKeys.BearerToken, response.accessToken)
                    localStorage.setItem(LocalStorageKeys.BearerTokenExpiryUtc, response.accessTokenExpiryUtc)
                    localStorage.setItem(LocalStorageKeys.RefreshToken, response.refreshToken)
                    isSuccess = true;
                }
            })
        .catch(err => {
            console.log(err);
        });

    return isSuccess;
}

function logout()
{
    localStorage.removeItem(LocalStorageKeys.BearerToken);
    localStorage.removeItem(LocalStorageKeys.BearerTokenExpiryUtc);
    localStorage.removeItem(LocalStorageKeys.User);
    localStorage.removeItem(LocalStorageKeys.RefreshToken);
    osnDB.delete();

    window.location.pathname = "/login";
}