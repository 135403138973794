export default {
    logDev(string, optionalParams)
    {
        if(process.env.VUE_APP_ENV == LogEnvironment.DEV())
        {
            console.log(string, optionalParams);
        }
    },
    logQa(string)
    {
        if(process.env.VUE_APP_ENV == LogEnvironment.QA())
        {
            console.log(string);
        }
    },
    logProd(string)
    {
        if(process.env.VUE_APP_ENV == LogEnvironment.PROD())
        {
            console.log(string);
        }
    },
    log(string)
    {
        console.log(string);
    }
}

export class LogEnvironment {
    static DEV() { return 'development'}
    static QA() { return 'qa'}
    static PROD() {return 'prod'}
}