export const LocalStorageKeys =
{
    BearerToken : 'BearerToken',
    BearerTokenExpiryUtc : 'BearerTokenExpiryUtc',
    RefreshToken : 'RefreshToken',
    User: 'User',
    PublicDirectory: 'Directory'
}

import {appsettings} from './appsettings.js';
export const ReCaptchaKey = appsettings.reCaptchaKey
let host = appsettings.host
let baseUri = host+'api/'
let apiV1Uri = baseUri;

let Controllers = 
{
    Account: apiV1Uri+'account/',
    Authentication : apiV1Uri+'authentication/',
    ChatRooms: apiV1Uri+'chatRooms/',
    User : apiV1Uri+'user/',
    HelpRequest : apiV1Uri+'HelpRequest/',
    SystemTest: apiV1Uri+'System/',
    Email : apiV1Uri+'Email',
    Responder: apiV1Uri+'Responder/',
    PublicDirectory: apiV1Uri+'PublicDirectory/',
    Trips: apiV1Uri+'Trips/'
}

export const EndPoints = 
{
    Host: host,
    Account: host+'api/account/',
    Authentication : host+'api/authentication/',
    ChatRooms: host+'api/chatRooms/',
    User : host+'api/user/',
    HelpRequest : host+'api/HelpRequest/',
    SystemTest: {
        Base: Controllers.SystemTest,
        Ping: Controllers.SystemTest+"ping/"
    },
    Email : host+'api/Email',
    Responder: host+'api/Responder/',
    PublicDirectory: host+'api/PublicDirectory/',
    Trips: host+'api/Trips/'
}

export const BootstrapIconClass = 
{
    Member :'bi bi-person-badge-fill',
    TripCheckedIn :'bi bi-calendar-check-fill',
    TripOverDue : 'bi bi-calendar-x-fill',
    Share: 'bi bi-share-fill',
    Required: 'bi bi-asterisk',
    Edit: 'bi bi-pencil-square',
    Expand: 'bi bi-arrows-angle-expand',
    SentFromSat: 'bi bi-cloud-arrow-up-fill',
    ReceivedFromSat: 'bi bi-cloud-arrow-down-fill',
    Archive: 'bi bi-archive-fill',
    //Delete: 'bi bi-x-circle'
    Delete: 'bi bi-trash-fill'
}