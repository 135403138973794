import { createRouter, createWebHistory } from 'vue-router'
//import Home from '../views/Home.vue'
import {LocalStorageKeys} from '../constants/consts.js';
import {User} from '../models/User.js';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/RequestHelp.vue')
    //component: Home
  },
  {
    path: '/login',
    name: "Login",
    component: () => import('../views/Login.vue')
  },
  {
    path: '/user',
    name: 'User',
    component: () => import(/* webpackChunkName: "about" */ '../views/User.vue')
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/help',
    name: 'Help',
    component: () => import('../views/RequestHelp.vue')
  },
  {
    path: '/requests',
    name: 'Requests',
    component: () => import('../views/HelpRequests.vue')
  },
  {
    path: '/chats',
    name: 'Chats',
    component: () => import('../views/Chats.vue')
  },
  {
    path: '/verifyemail',
    name: 'VerifyEmail',
    component: () => import('../views/VerifyEmail.vue')
  },
  {
    path: '/resetpassword',
    name:'ResetPassword',
    component: () => import('../views/ResetPassword.vue')
  },
  {
    path: '/user',
    name:'User',
    component: () => import('../views/User.vue'),
    props: {user: new User}
  },
  {
    path: '/account',
    name:'Account',
    component: () => import('../views/Account.vue')
  },
  {
    path: '/contacts',
    name:'Contacts',
    component: () => import('../views/Contacts.vue')
  },
  {
    path: '/directory',
    name:'PublicDirectory',
    component: () => import('../views/PublicDirectory.vue')
  },
  {
    path: '/trips',
    name:'Trips',
    component: () => import('../views/Trips.vue'),
    children: [
      {
        path: 'new',
        name:'NewTrip',
        component: () => import('../views/Trip.vue')
      },
      {
        path: ':id',
        name:'Trip',
        component: () => import('../views/Trip.vue')
      },
    ]
  },
  {
    path: '/trips/:guid/preview',
    name: 'TripPreview',
    component: () => import('../views/TripPreview.vue')
  }
]

const router = createRouter({
  //history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})

router.beforeEach( (to, from, next) =>
{
  //console.log(to)
  if(to.name.toLowerCase() === 'verifyemail')
  {
    next();
    return;
  }

  if(to.name.toLowerCase() === 'resetpassword')
  {
    next();
    return;
  }

  if(to.name.toLowerCase() === 'trippreview')
  {
    next();
    return;
  }

  if(localStorage.getItem(LocalStorageKeys.BearerToken) === null)
  {
    if(to.name.toLowerCase() !== 'login')
      next( {name: 'Login'});
    else
      next();
  }
  //else if(to.name.toLowerCase() !== 'login' && localStorage.getItem(LocalStorageKeys.BearerToken) === null)
  //  next( {name: 'Login'})
  else if(to.name.toLowerCase() === 'login')
  {
    //user is either logged out or not logged in
    //if we had a 401 error due to stale BearerToken; this will clear the stale cached info. Hence preventing the "menu" showing up in Login screen
    localStorage.removeItem(LocalStorageKeys.BearerToken)
    localStorage.removeItem(LocalStorageKeys.User)
    next();
    return;
  }
  else if(to.name.toLowerCase() === 'home')
    next( {name: 'Help'});
  else
    next();
})

export default router
