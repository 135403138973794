import {osnDB} from "@/stores/db";

export class BaseStore {

    static _db = osnDB; //TODO: make private readonly
    #storeName = '';

    constructor(storeName) {
        if(!storeName)
            throw new Error("Invalid storeName");

        this.#storeName = storeName;
    }

    mapToClass(constructor) {
        BaseStore._db.db.table(this.#storeName).mapToClass(constructor);
    }

    insertOrReplace(obj) {
        return BaseStore._db.insertOrReplace(this.#storeName, obj);
    }

    update(key, changes) {
        return BaseStore._db.update(this.#storeName, key, changes);
    }

    getAllData() {
        return BaseStore._db.getAllData(this.#storeName);
    }

    getData(ids) {
        return BaseStore._db.getData(this.#storeName, ids);
    }
}