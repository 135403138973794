<template>
   <div class="alert alert-danger" role="alert" v-if="!isSystemOnline">
       Service is NOT available right now.
    </div> 
</template>

<script>
import {EndPoints} from '../constants/consts.js';

export default {
    name:"system-test",
    props: {
        keepTesting: Boolean
    },
    data() {
        return {
            isSystemOnline: this.keepTesting,
            timer: Object
        }
    },
    watch: {
        keepTesting(val)
        {
            if(val)
                this.timer = setInterval( () => {this.checkApi()}, 10000);
            else
                clearInterval(this.timer);
        },
        isSystemOnline(val)
        {
            if(val)
                clearInterval(this.timer);
            else
                this.timer = setInterval( () => {this.checkApi()}, 10000);
        }
    },
    emits: ['systemChecked'],
    created() {
        this.checkApi()
    },
    methods: {
        checkApi: function()
        {
            fetch(EndPoints.SystemTest.Base, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => { 
                if(response.ok){
                    this.isSystemOnline = true
                }
                else{
                    this.isSystemOnline = false
                }

                this.$emit('systemChecked', this.isSystemOnline)
            })
            .catch(err => { 
                console.log(err)
                this.isSystemOnline = false
                this.$emit('systemChecked', this.isSystemOnline)
            });
        }
    }
}
</script>