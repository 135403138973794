export class ChatRoom {
    id= -1;
    participants= [];
    title= '';
    description= '';
    isActive = false;
    unreadCount = 0;
    lastMessageId = -1;

    static FromJson(json)
    {
        let temp = new ChatRoom();

        Object.keys(json).forEach(key => {
            //going through json object keys, so that temp default values are not overwritten with undefined
            // if they don't exist in json.keys
            temp[key] = json[key];
        })
        return temp;
    }
}