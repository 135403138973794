import {LocalStorageKeys, EndPoints} from '../constants/consts.js';
import {User} from '../models/User.js';
import osnFetch from '../utilities/osnFetch.js';
import logger from './logger.js'
import _ from 'lodash';

//If userString is in LocalStorage retrieves it, otherwise fetches it from the api and puts into the localstorage and returs user object
//If (forceRetrieve === true) then enforce fetch user object even if it's in the local storage
export default async function(forceRetrieve) {
    let user = new User

    let userString = (forceRetrieve === true) ? null : localStorage.getItem(LocalStorageKeys.User)

    if(userString == null || userString == undefined || _.isEmpty(userString) || userString === '{}')
    {
        user = await fetchUser()
        
        //DO NOT WANT TO STORE THIS AS IT'S NOT SECURE
        //localStorage.setItem(LocalStorageKeys.User, JSON.stringify(user)) 
    }
    else
    {
        user = JSON.parse(userString)
    }

    return user;
}

async function fetchUser() {
    let user = {} 
    await osnFetch(EndPoints.User, 'GET')
        .then(response => { 
            if(response.ok){
                return response.json()
            }
            else if(response.status == "401")
            {
                logger.logDev('getUser.fetchUser returned 401');
                this.$router.push('Login')
                console.log("Server returned " + response.status + " : " + response.statusText)
            }
            else{
                console.log("Server returned " + response.status + " : " + response.statusText)
            }                
        })
        .then(response => 
            {
                if(response !== undefined)
                {
                    user = response
                    //this.user = response
                    //this.originalUser = _.cloneDeep(response)
                }
                else
                {
                    user = new User
                }

                logger.logDev("Got the user")
            })
        .catch(err => {
            logger.log(err);
        });

    return user
}