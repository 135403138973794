import {EndPoints} from '@/constants/consts';
import osnFetch from './osnFetch.js';
import { chatHub } from '@/globals/hubs'
import {HubConnectionState} from "@microsoft/signalr";
import {ChatRoom} from "@/models/ChatRoom";
import { dbStoreFactory } from '../stores/DBStoreFactory.js';
import { DBStores } from '../stores/DBStores.js';

class ChatRoomsService {
    _isLoading = false;
    _unreadMessages = { total: 0 };

    connect() {
        chatHub.receiveMessage((response) => this.receiveMessage(response));
        chatHub.receiveOwnMessage((response) => this.receiveOwnMessage(response));
        chatHub.joinChatRoom((response) => this.joinChatRoom(response));

        if(chatHub.state !== HubConnectionState.Connected)
          chatHub.start();
    }

    disconnect() {
        chatHub.stop();
    }

    async downloadChatRooms() {
        this._isLoading = true;
        let chatRoomCount = 0;
        let chatRoomsLoaded = 0;

        return osnFetch(EndPoints.ChatRooms, "GET")
        .then(response => {
            if (!response.ok || response.status == "204") {
                this._isLoading = false;
                return;
            }
            
            response.json()
            .then(chatRooms => {
                //TODO: might want to figure out a way to resolve this before moving to downloading messages
                chatRoomCount = chatRooms.length;

                if(chatRoomCount === 0) {
                    this._isLoading = false;
                    return;
                }

                //TODO: since we only call downloadChatRooms() function on Log In, and on Log Out we clean the indexDB, this function can be simplified to call downloadChatRoomMessages() directly??
                chatRooms.forEach(cr => {
                    dbStoreFactory.getStore(DBStores.ChatRoom).update(cr.id, cr)
                        .then((updated) => {
                        //if chatRoom exists, only updated what ever is in the cr object.
                        //leaving lastMessageId & unreadCount intact as they do not come from the server
                        //otherwise insert the new ChatRoom object.
                        //we can update this if we ever store lastMessageId & unreadCount on the server
                        if (updated)
                            return cr.id;

                        return new Promise ( (resolve) => {
                            dbStoreFactory.getStore(DBStores.ChatRoom).insertOrReplaceChatRoom(ChatRoom.FromJson(cr))
                            .then( () => {
                                resolve(cr.id);
                            });
                        });
                    })
                    .then( id => {
                        return dbStoreFactory.getStore(DBStores.ChatRoom).getData(id);
                    })
                    .then( cr => {
                        this.downloadChatRoomMessages(cr, cr.lastMessageId )
                            .then( () => {
                                this._isLoading = ++chatRoomsLoaded < chatRoomCount;
                            });
                    });
                });
            })
            .catch( e => {
                console.log("error:", e);
            });
            //TODO: add Finally block here to set this._isLoading = false. But will have to refactor chatRooms.forEach block to chain promise properly
        });
    }

    downloadChatRoomMessages(chatRoom, lastMessageID = -1) {
        const endPoint = EndPoints.ChatRooms+chatRoom.id+ '/messages/'+lastMessageID;

        return osnFetch(endPoint, "GET")
        .then(response => { 
            if(response.ok){
                return response.json()
            }
            else{
                console.log("Server returned " + response.status + " : " + response.statusText)
            }                
        })
        .then(response => {
            //TODO: this can move to the caller method
            if(response === null || response === undefined)
                return;

            if(response.length == 0 || !response.length)
            {
                return;
            }

            dbStoreFactory.getStore(DBStores.ChatRoomMessages).insertOrReplaceChatRoomMessages(chatRoom.id, response);
            dbStoreFactory.getStore(DBStores.ChatRoom).update(chatRoom.id, {"lastMessageId": response.at(-1).id, "unreadCount":response.length});

            if(chatRoom.isActive)
                this.addToUnreadMessages(chatRoom.id, response.length);
        });
    }

    getTotalUnreadMessagesCount() {
        return this._unreadMessages["total"];
    }

    getUnreadMessageCount(chatRoomId) {
        if(this._unreadMessages[chatRoomId])
           return this._unreadMessages[chatRoomId];

           //todo: reactivity doesn't work because this is a promise, breaks badges on UI?
/*         dbStoreFactory.getStore(DBStores.ChatRoom).getData(chatRoomId)
        .then(chatRoom => {
            return chatRoom.unreadCount;
        }); */
        return 0;
    }

    addToUnreadMessages(chatRoomId, newMessageCount=1) {
        //todo: this should be addition
        dbStoreFactory.getStore(DBStores.ChatRoom).update(chatRoomId, { "unreadCount": newMessageCount });

        if(this._unreadMessages[chatRoomId])
            this._unreadMessages[chatRoomId] += newMessageCount;
        else
            this._unreadMessages[chatRoomId] = newMessageCount;

        this._unreadMessages["total"]+= newMessageCount;
    }

    clearUnreadMessages(chatRoomId) {
        dbStoreFactory.getStore(DBStores.ChatRoom).update(chatRoomId, { "unreadCount": 0 });
        let count = this._unreadMessages[chatRoomId] ?? 0;
        this._unreadMessages[chatRoomId] = 0;
        this._unreadMessages["total"] -= count;
    }
    
    sendMessage(chatMessage /*ChatMessage*/) {
        //causes error in hub.js if chatHub is a class property
        return chatHub.sendMessage(chatMessage.chatRoomId, chatMessage.messageText);
    }

    receiveOwnMessage(response) {
        dbStoreFactory.getStore(DBStores.ChatRoomMessages).insertOrAppendChatRoomMessages(response.chatRoomId, [response]).then(() => {
            this.onMessageReceived(response.chatRoomId);
        });
    }

    receiveMessage(response) {
        dbStoreFactory.getStore(DBStores.ChatRoomMessages).insertOrAppendChatRoomMessages(response.chatRoomId, [response]).then(() => {
            this.addToUnreadMessages(response.chatRoomId);
            this.onMessageReceived(response.chatRoomId);
        });
    }

    joinChatRoom(chatRoom) {
        dbStoreFactory.getStore(DBStores.ChatRoom)
            .insertOrReplaceChatRoom(chatRoom)
            .then( () => {
                this.downloadChatRoomMessages(chatRoom);
            });
    }

    async getChatRooms()
    {
        while(this._isLoading) {
            await this.timer(1000);
        }

        return dbStoreFactory.getStore(DBStores.ChatRoom).getData();
    }

    async getChatRoomMessages(chatRoomId)
    {
        while(this._isLoading) {
            await this.timer(1000);
        }

        return dbStoreFactory.getStore(DBStores.ChatRoomMessages).getMessages(chatRoomId);
    }

    //being subscribed to
    onMessageReceived = () => {}; 

    // Returns a Promise that resolves after "ms" Milliseconds
    timer = ms => new Promise(res => setTimeout(res, ms))
}

export let chatRoomsService = new ChatRoomsService();