export default class locale{
    //ideally I'd load this in CreateApp().use(locale) in main.js;
    //but would have to set this either as a function or with an 'install' in class. 
    //Check Vue plugin development??
    static defaultRegion = "en-CA";
    userRegion = {};

    constructor(regionCode) {
        try
        {
            this.userRegion = require('./'+regionCode+'.json');
            //console.log('loaded '+regionCode+' locale file');
        }
        catch{
            //console.log('Error while loading '+regionCode+' locale file.Loading default.');
            this.userRegion = require('./'+locale.defaultRegion+'.json');
        }
    }

    loadLocale(regionCode) 
    {
        try
        {
            this.userRegion = require('./'+regionCode+'.json');
            console.log('loaded '+regionCode+' locale file');
        }
        catch{
            console.log('Error while loading '+regionCode+' locale file.Loading default.');
            this.userRegion = require('./'+locale.defaultRegion+'.json');
        }
    }

    getTerm(term)
    {
        let dict = this.userRegion["dictionary"];
        return dict[term.toLowerCase()];
    }

}