<template>
    <div class="row text-start">
        <hr />
        <div class="col-sm-3">
            <h5 class="fw-bold">Contact Us</h5>
            <span>For any inquiry or suggestion email our <a href="mailto:support@overlandsupport.net" class="text-decoration-none">support</a></span>
       </div>
        <div class="col-sm-3">
            <h5 class="fw-bold">Support Us</h5>
            <span>We are a small development team. Please consider <a href="https://www.buymeacoffee.com/overlandsupport" target="_blank">supporting us</a>, as well as follow to get news of our periodic updates.</span>
       </div>
       <div class="col-sm-4">
            <h5 class="fw-bold">Blog</h5>
            <div v-for="(post, id) in this.blogPosts" :key="id"><span v-if="post.publish">{{post.header}} - <a :href="post.link.href" target="_blank">{{post.link.text}}</a></span></div>
       </div>
       <div class="col-sm-2">
            <h6 class="fw-bold">Updated On</h6>
            <span>{{buildDateTime.toDateString()}}</span><br/>
            <span>{{buildDateTime.toLocaleTimeString()}}</span>
       </div>
   </div> 
   <br/>
   <br/>
</template>

<script>
import blogs from '../blog/blogs.js';
export default {
    name: 'footer-component',
    data() {
        return {
            buildDateTime: new Date(document.documentElement.dataset.buildTimestampUtc),
            blogPosts: new blogs().blogPosts
        }
    }
}
</script>