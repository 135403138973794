<template>
  <system-test :keep-testing="!this.isUserLoggedIn" v-on:systemChecked="systemCheck($event)"/>
  <div class="alert alert-danger" v-if="this.chatConnectionState && this.chatConnectionState != 'Connected'">
      <p>Lost Connection with chat server : <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> {{ this.chatConnectionState }}</p>
  </div>
  <nav id="navBar" v-if="isShowMenu" class="navbar navbar-expand-md navbar-light bg-white">
    <div class="container-fluid">
      <a class="navbar-brand">
        <img src="./assets/overlandTransparentBlack.png" class="img-fluid" style="height:125px;width:125px" alt="Overland Support Network" />
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mainNav" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="mainNav">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item mt-1 me-1">
            <router-link to="/help" class="nav-link btn btn-primary">
              <i class="bi bi-broadcast-pin me-1"></i>
              <span :class="this.menuLabelSpanClass">{{l18n.getTerm('request_help')}}</span>
            </router-link>
          </li>
          <li class="nav-item mt-1 me-1">
            <router-link to="/requests" class="nav-link btn btn-primary">
              <i class="bi bi-exclamation-triangle-fill me-1"></i>
              <span :class="this.menuLabelSpanClass">{{l18n.getTerm('help_requests')}}</span></router-link>
          </li>
          <li class="nav-item mt-1 me-1 position-relative" v-if="user.isUserActive" v-on:click="unreadMessageCount = 0;">
            <router-link to="/chats" class="nav-link btn btn-primary">
              <i class="bi bi-chat-dots-fill"></i>
              <span :class="this.menuLabelSpanClass">{{l18n.getTerm('chats')}}</span>
              <span v-if="this.chatRoomService.getTotalUnreadMessagesCount() > 0" class="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                <span class="visually-hidden">unread messages</span>
              </span>
          </router-link>
          </li>
          <li class="nav-item mt-1 me-1" v-if="user.isUserActive">
            <router-link to="/directory" class="nav-link btn btn-primary">
              <i class="bi bi-person-rolodex me-1"></i>
              <span :class="this.menuLabelSpanClass">{{l18n.getTerm('public_directory')}}</span></router-link>
          </li>
          <div class="btn-group mt-1 me-1 text-start" role="group">
            <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bi bi-calendar-range me-1"></i>
              <span :class="this.menuLabelSpanClass">{{l18n.getTerm('Trips')}}</span>
            </button>
            <ul class="dropdown-menu bg-primary" aria-labelledby="btnGroupDropTrips">
              <li>
                <router-link to="/trips/new" class="nav-link btn btn-primary text-start mt-1">
                  <i class="bi bi-calendar-plus me-1"></i>
                  <span>New Trip</span>
                </router-link>
              </li>
              <li>
                <router-link to="/trips/" class="nav-link btn btn-primary text-start mt-1">
                  <i class="bi bi-calendar-range me-1"></i>
                  <span>Trips</span>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="btn-group mt-1 me-1" role="group">
            <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bi bi-gear-fill me-1"></i>
              <span :class="this.menuLabelSpanClass">{{l18n.getTerm('Settings')}}</span>
            </button>
            <ul class="dropdown-menu bg-primary" aria-labelledby="btnGroupDropSettings">
              <li>
                <router-link to="/user" class="nav-link btn btn-primary text-start mt-1">
                  <i class="bi bi-person-fill me-1"></i>
                  <span>{{l18n.getTerm('User')}}</span>
                </router-link>
              </li>
              <li>
                <router-link to="/account" class="nav-link btn btn-primary text-start mt-1">
                  <i class="bi bi-person-plus-fill me-1"></i>
                  <span>{{l18n.getTerm('Account')}}</span>
                </router-link>
              </li>
              <li>
                <router-link to="/contacts" class="nav-link btn btn-primary text-start mt-1">
                  <i class="bi bi-person-rolodex me-1"></i>
                  <span>{{l18n.getTerm('Contacts')}}</span>
                </router-link>
              </li>
              <!--<li><a class="nav-link dropdown-item bg-primary" href="/user">User</a></li>
              <li><a class="nav-link dropdown-item bg-primary" href="/account">Account</a></li>-->
            </ul>
          </div>
        </ul>
        <span class="navbar-text">
          <log-out class="nav-link btn btn-primary"/>
        </span>
      </div>
    </div>
  </nav>
  <div class="alert alert-danger" v-if="isShowMenu && !user.isUserActive">
      <p>Your account is <b>NOT</b> active until you fill your personal information and your base location and your email is verified. Which means you will <b>not be able</b> to request help or respond to help requests.</p>
  </div>
  <div class="container-fluid mt-2" style="min-height:400px">
    <router-view :user="user" v-on:notify-user="notify" />
  </div>
  <div class="container mt-5">
    <Footer></Footer>
  </div>
  <toast-component :toast="this.toast"/>
</template>

<script>
import Logout from './components/Logout.vue'
import {LocalStorageKeys} from './constants/consts.js';
import SystemTest from './components/SystemTest.vue';
import Footer from './components/Footer.vue';
import ToastComponent from './components/Toast.vue';
import {User} from './models/User.js';
import {Toast} from './models/Toast.js';
//various
import GetUser from './utilities/getUser.js';
import logger from './utilities/logger';
import locale from './localization/locale.js';
import {chatRoomsService} from './utilities/chatRoomsService.js';
import { helpRequestService } from './utilities/helpRequestService.js';
import { chatHub } from './globals/hubs.js';

export default {
  components: {
    'log-out':Logout, 
    'system-test':SystemTest, 
    'toast-component':ToastComponent,
    Footer 
  },
  data() {
    return {
      LocalStorageKeys : LocalStorageKeys,
      isUserLoggedIn: false,
      user: new User,
      isSystemOnline: true,
      toast: new Toast(),
      l18n: new locale('en-CA'),
      chatRoomService: chatRoomsService, //to initiate ChatRoomService construction. Better to move this to global properties??
      helpRequestService: helpRequestService, //to initiate ChatRoomService construction. Better to move this to global properties??
      chatConnectionState: null,
    }
  },
  computed: {
    menuLabelSpanClass() {
      return "d-sm-none d-lg-inline";
    },
    isShowMenu() {
      return this.isUserLoggedIn && this.$route.name !== 'VerifyEmail';
    }
  }, 
  created(){
    this.userLogInStatus();
    chatHub.onConnectionStateChanged = this.chatConnectionStateChanged;
    logger.log("env : " + process.env.VUE_APP_ENV);
  },
  watch: {
    '$route': function() {
      // lets watch for route changes on our
      // main parent app component.
      logger.logDev('route change on main parent app');
      this.userLogInStatus();
    },
    'isUserLoggedIn': function(val) {
      if(val)
      {
        this.chatRoomService.downloadChatRooms();
        this.chatRoomService.connect();
        this.helpRequestService.getActiveRequest();
      }
      else
      {
        this.chatRoomService.disconnect();
      }
    }
  },
  methods: {
    chatConnectionStateChanged(state) {
      this.chatConnectionState = state;
    },
    systemCheck: function(val) {
      this.isSystemOnline = val
      if(!val)
      {
        localStorage.removeItem(LocalStorageKeys.BearerToken)
        localStorage.removeItem(LocalStorageKeys.User)
        this.$router.push({name:"Login"})
      }
    },
    logout: function() {
      this.userLogInStatus();
    },
    userLogInStatus: async function() {
      this.isUserLoggedIn = !(localStorage.getItem(this.LocalStorageKeys.BearerToken) === null)

      console.log("App.userLogInStatus: " + this.isUserLoggedIn);
      if(this.isUserLoggedIn)
      {
        //this is to pre-fetch and cache the user object. 
        await GetUser().then(response => { 
            this.user = response;
        });
      }
    },
    notify(event)
    {
      //TODO: Check if this causes memory leak issues, use profiler on the browser
      this.toast = event
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#mainNav  a {
  color: white;
}

#mainNav  a.router-link-exact-active {
  color: orangered;
}
ul[id$="country-listbox"] {
  /*z-index needs to be high so that phone number flag dropdown renders on top of everything,
    had conflicts with bootstrapper z-indices 
   */
  z-index: 10;
}
/*
[phone number input]A couple of CSS fixes are required to get the plugin to play nice with Bootstrap input groups. 
 You can see a Codepen here : http://codepen.io/jackocnr/pen/EyPXed
*/
.iti__arrow { border: none; }

.intl-tel-input {
  display: table-cell;
}
.intl-tel-input .selected-flag {
  z-index: 4;
}
.intl-tel-input .country-list {
  z-index: 5;
}
.input-group .intl-tel-input .form-control {
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 0;
}
</style>