<template>
   <button v-on:click="logout" alt="logout"><i class="bi bi-power"></i></button>
</template>

<script>
import {LocalStorageKeys} from '../constants/consts.js'
import { osnDB } from '../stores/db.js';
export default {
   methods: {
        logout: function() { 
            localStorage.removeItem(LocalStorageKeys.BearerToken);
            localStorage.removeItem(LocalStorageKeys.BearerTokenExpiryUtc);
            localStorage.removeItem(LocalStorageKeys.User);
            localStorage.removeItem(LocalStorageKeys.RefreshToken);
            osnDB.delete();
            this.$router.push({name:"Login"})
        }
   } 
}
</script>