import osnFetch from "./osnFetch";
import { EndPoints } from "@/constants/consts";
import { HelpRequest  } from "../models/HelpRequest";
import { DBStores } from "../stores/DBStores";
import { dbStoreFactory } from "../stores/DBStoreFactory";

class HelpRequestService {

    _userHelpRequest = null;
    helpRequests = {
        open: [],
        inProgress: [],
        responded: []
    };

    hasActiveRequest() {
        if(this._userHelpRequest)
            return true;

        return false;
    }

    getHelpRequestById(id) {
        const store = dbStoreFactory.getStore(DBStores.HelpRequest);
        return store.getData(id);
    }

    getOpenRequests() {
        return this.helpRequests.open;
    }

    getInProgressRequests() {
        return this.helpRequests.inProgress;
    }

    getRespondedRequests() {
        return this.helpRequests.responded;
    }

    getActiveRequest() {
        osnFetch(EndPoints.HelpRequest, "GET", null)
        .then(response => { 
            if(response.ok && response.status == "200"){
                return response.json()
            }
            else{
                console.log("Server returned " + response.status + " : " + response.statusText)
            }                

            return false;
        })
        .then(response => 
        {
            if(response)
            {
                this._userHelpRequest = response;
                return;
            }

            this._userHelpRequest = null;
        })
        .catch(err => {
            console.log(err);
        });
    }

    async getAllRequestsByResponder()
    {
        return osnFetch(EndPoints.HelpRequest+'requestsByResponder', 'GET')
        .then(response => { 
            if(response.ok){
                return response.json()
            }
            else{
                console.log("Server returned " + response.status + " : " + response.statusText)
            }                
        })
        .then(response => {
            if(!response)
                return;

            const store = dbStoreFactory.getStore(DBStores.HelpRequest);

            this.helpRequests.open.length = 0;
            response.openRequests.forEach(element => {
                let hr = HelpRequest.FromJson(element);
                this.helpRequests.open.push(hr);
                store.insertOrReplaceHelpRequest(hr); 
            });

            this.helpRequests.inProgress.length = 0;
            response.inProgressRequests.forEach(element => {
                let hr = HelpRequest.FromJson(element);
                this.helpRequests.inProgress.push(hr);
                store.insertOrReplaceHelpRequest(hr); 
            });

            this.helpRequests.responded.length = 0;
            response.respondedRequests.forEach(element => {
                let hr = HelpRequest.FromJson(element);
                this.helpRequests.responded.push(hr);
                store.insertOrReplaceHelpRequest(hr); 
            });
        })
        .catch(err => {
            console.log(err);
        });
    }
}

export const helpRequestService = new HelpRequestService();