export class Toast {
    constructor(msg = '', toastType = '') {
        this.message = msg;
        this.type = toastType;
    }

    message= ''
    type= ''
    duration= 5000 // milliseconds
}

export const ToastType = {
    ERROR: 'alert',
    ALERT: 'alert',
    INFO: 'info'
}